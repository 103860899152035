import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Spin } from 'antd';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Error404 from './Error404';
import App from './app/App';
import { ROUTES } from './common/constants';
import history from './historyData';

const Routes = () => {
  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error?.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  Spin?.setDefaultIndicator(<LoadingOutlined className="font-size-32" spin />);

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <Route path={ROUTES?.SLUG} component={App} />
          <Route path="*" exact component={Error404} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
