import { useLoadScript } from '@react-google-maps/api';
import { Button, Form, message } from 'antd';
import { debounce, findIndex } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { AppContext } from '../AppContext';
import MarkerIcon from '../assets/marker-icon.svg';
import api from '../common/api';
import { formValidatorRules, sendAnalyticsData } from '../common/utils';
import GoogleMapComponent from './GoogleMapComponent';
import LoaderComponent from './LoaderComponent';
import NumberComponent from './NumberComponent';
import './styles/PostalWidgetComponent.less';

const libraries = ['places'];
const { zipCode } = formValidatorRules;

const PostalWidgetComponent = ({
  widgetConfig = null,
  setSavedData,
  form = null
}) => {
  const {
    state: { primaryColor, storedData, showSubmitBtn },
    dispatch
  } = useContext(AppContext);
  const [markerObj, setMarkerObj] = useState(null);
  const [geoLoading, setGeoLoading] = useState(false);
  const [zipCodeValidationLoading, setZipCodeValidationLoading] = useState(
    false
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process?.env?.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const zipCodeValue = Form?.useWatch('zipCode', form);
  const inputRef = useRef(null);

  const updateHeight = () => {
    // eslint-disable-next-line no-undef
    const mapComponent = document?.getElementsByClassName(
      'common-map-cpq'
    )?.[0];
    if (mapComponent) {
      mapComponent.style.height = `${mapComponent?.clientWidth}px`;
    }
  };

  const getLocationFromPostalCode = (zipCodeParam) => {
    if (zipCodeParam) {
      setZipCodeValidationLoading(true);
      api
        ?.get('/map-api', {
          params: {
            address: zipCodeParam
          }
        })
        .then((response) => {
          const address =
            response?.data?.results?.length > 0 &&
            response?.data?.results?.[0]?.formatted_address;

          if (address?.slice(address?.length - 3, address?.length) === 'USA') {
            const {
              lat,
              lng
            } = response?.data?.results?.[0]?.geometry?.location;
            let cityShortName;
            let stateShortName;
            let cityLongName;
            let stateLongName;
            const localityIndex = findIndex(
              response?.data?.results?.length > 0 &&
                response?.data?.results?.[0]?.address_components,
              (item) => item?.types?.includes('locality')
            );
            for (
              let i = 0;
              i < response?.data?.results?.[0]?.address_components?.length;
              // eslint-disable-next-line no-plusplus
              i++
            ) {
              for (
                let j = 0;
                j <
                response?.data?.results?.[0]?.address_components?.[i]?.types
                  ?.length;
                // eslint-disable-next-line no-plusplus
                j++
              ) {
                switch (
                  response?.data?.results?.[0]?.address_components?.[i]
                    ?.types?.[j]
                ) {
                  case 'locality':
                    if (localityIndex > -1) {
                      cityLongName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.long_name;
                      cityShortName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.short_name;
                    }
                    break;
                  case 'neighborhood':
                    if (localityIndex < 0) {
                      cityLongName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.long_name;
                      cityShortName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.short_name;
                    }
                    break;
                  case 'administrative_area_level_1':
                    stateLongName =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.long_name;
                    stateShortName =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.short_name;
                    break;

                  default:
                    break;
                }
              }
            }
            setMarkerObj({ lat, lng });
            dispatch({
              type: 'SET_SHOW_SUBMIT_BTN',
              data: true
            });
            dispatch({
              type: 'SET_LOCATION_NAME',
              data: `${cityShortName}, ${stateShortName}`
            });
            form?.setFieldsValue({
              city: cityLongName,
              state: stateLongName
            });
            const postalCodeObj = {
              lat,
              lng,
              city: cityLongName,
              state: stateLongName,
              zipCode: zipCodeParam,
              shortAddress: `${cityShortName}, ${stateShortName}`
            };
            setSavedData(postalCodeObj);
            setZipCodeValidationLoading(false);
          } else {
            dispatch({
              type: 'SET_LOCATION_NAME',
              data: ''
            });
            setMarkerObj(null);
            setSavedData(null);
            message?.error('Location not serviceable');
            dispatch({
              type: 'SET_SHOW_SUBMIT_BTN',
              data: false
            });
            setZipCodeValidationLoading(false);
          }
        })
        .catch(() => {
          dispatch({
            type: 'SET_LOCATION_NAME',
            data: ''
          });
          setMarkerObj(null);
          setSavedData(null);
          message?.error('Please enter valid postal code.');
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: false
          });
          setZipCodeValidationLoading(false);
        });
    }
  };
  const getGeoLocation = () => {
    setGeoLoading(true);
    form?.setFieldsValue({ zipCode: '' });
    dispatch({
      type: 'SET_LOCATION_NAME',
      data: ''
    });
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    const success = (pos) => {
      const crd = pos?.coords;
      api
        ?.get('/map-api', {
          params: {
            latLng: `${crd?.latitude},${crd?.longitude}`
          }
        })
        .then((response) => {
          const address =
            response?.data?.results?.length > 0 &&
            response?.data?.results?.[0]?.formatted_address;
          if (address?.slice(address?.length - 3, address?.length) === 'USA') {
            let zip;
            let cityShortName;
            let stateShortName;
            let cityLongName;
            let stateLongName;
            const localityIndex = findIndex(
              response?.data?.results?.length > 0 &&
                response?.data?.results?.[0]?.address_components,
              (item) => item?.types?.includes('locality')
            );
            for (
              let i = 0;
              i < response?.data?.results?.[0]?.address_components?.length;
              // eslint-disable-next-line no-plusplus
              i++
            ) {
              for (
                let j = 0;
                j <
                response?.data?.results?.[0]?.address_components?.[i]?.types
                  ?.length;
                // eslint-disable-next-line no-plusplus
                j++
              ) {
                switch (
                  response?.data?.results?.[0]?.address_components?.[i]
                    ?.types?.[j]
                ) {
                  case 'postal_code':
                    zip =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.long_name;
                    form?.setFieldsValue({ zipCode: zip });
                    break;
                  case 'locality':
                    if (localityIndex > -1) {
                      cityLongName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.long_name;
                      cityShortName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.short_name;
                    }
                    break;
                  case 'neighborhood':
                    if (localityIndex < 0) {
                      cityLongName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.long_name;
                      cityShortName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.short_name;
                    }
                    break;
                  case 'administrative_area_level_1':
                    stateLongName =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.long_name;
                    stateShortName =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.short_name;
                    break;
                  default:
                    break;
                }
              }
            }
            setMarkerObj({ lat: crd?.latitude, lng: crd?.longitude });
            dispatch({
              type: 'SET_LOCATION_NAME',
              data: `${cityShortName}, ${stateShortName}`
            });
            const postalCodeObj = {
              lat: crd?.latitude,
              lng: crd?.longitude,
              city: cityLongName,
              state: stateLongName,
              zipCode: zip,
              shortAddress: `${cityShortName}, ${stateShortName}`
            };
            setSavedData(postalCodeObj);
            dispatch({
              type: 'SET_SHOW_SUBMIT_BTN',
              data: true
            });
          } else {
            dispatch({
              type: 'SET_LOCATION_NAME',
              data: ''
            });
            setMarkerObj(null);
            setSavedData(null);
            dispatch({
              type: 'SET_SHOW_SUBMIT_BTN',
              data: false
            });
            message?.error('Location not serviceable');
          }
        })
        .catch(() => {
          dispatch({
            type: 'SET_LOCATION_NAME',
            data: ''
          });
          setMarkerObj(null);
          message?.error('Please enter valid postal code.');
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: false
          });
          setSavedData(null);
        });
      setGeoLoading(false);
    };

    const error = (err) => {
      message?.error(err?.message);
      setGeoLoading(false);
    };

    // eslint-disable-next-line no-undef
    navigator?.geolocation?.getCurrentPosition(success, error, options);
  };

  useEffect(() => {
    const currentQuoteObj = { ...storedData };
    inputRef?.current?.focus();
    if (currentQuoteObj) {
      // check whether we have leadId or not
      if (currentQuoteObj?.leadId && currentQuoteObj?.zipCode) {
        dispatch({
          type: 'SET_SHOW_SUBMIT_BTN',
          data: true
        });
      } else if (
        currentQuoteObj?.lat &&
        currentQuoteObj?.lng &&
        currentQuoteObj?.shortAddress &&
        currentQuoteObj?.zipCode
      ) {
        setMarkerObj({
          lat: currentQuoteObj?.lat,
          lng: currentQuoteObj?.lng
        });
        dispatch({
          type: 'SET_SHOW_SUBMIT_BTN',
          data: true
        });
      } else {
        dispatch({
          type: 'SET_SHOW_SUBMIT_BTN',
          data: false
        });
      }
      dispatch({
        type: 'SET_LOCATION_NAME',
        data: currentQuoteObj?.shortAddress
      });
      if (currentQuoteObj?.zipCode) {
        form?.setFieldsValue({
          zipCode: currentQuoteObj?.zipCode,
          state: currentQuoteObj?.state,
          city: currentQuoteObj?.city,
          street: currentQuoteObj?.street,
          name: currentQuoteObj?.name,
          email: currentQuoteObj?.email
        });
      } else {
        getGeoLocation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeZip = (e) => {
    const { value } = e?.target;
    if (value?.length < 3 || value?.length > 5 || parseInt(value, 10) === 0) {
      dispatch({
        type: 'SET_LOCATION_NAME',
        data: ''
      });
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
      setMarkerObj(null);
    } else if (value?.length === 5) {
      getLocationFromPostalCode(value);
    }
    if (!value) {
      form?.setFieldsValue({
        zipCode: null
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedZipHandler = useCallback(debounce(handleChangeZip, 500), []);

  useEffect(() => {
    updateHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({ zipCode: zipCodeValue });
  }, [zipCodeValue]);

  useEffect(() => {
    const loading = setInterval(function () {
      // eslint-disable-next-line no-undef
      if (document?.getElementsByClassName('common-map-cpq')?.[0]) {
        updateHeight();
        clearInterval(loading);
      }
    }, 100);
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateHeight);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateHeight);
  });

  return (
    <div className="postal-code-page">
      <LoaderComponent spinning={zipCodeValidationLoading}>
        {widgetConfig?.zipCodeWidget?.map && (
          <div className="map-section" id="map-area">
            {isLoaded && (
              <GoogleMapComponent
                markerObj={markerObj}
                loadError={loadError}
                fillColor={primaryColor}
              />
            )}
          </div>
        )}
        <div className="zip-section">
          <Form.Item name="zipCode" rules={[zipCode]}>
            <NumberComponent
              tabIndex={0}
              allowClear
              onKeyDown={(e) =>
                e?.keyCode === 13 && !showSubmitBtn ? e?.preventDefault() : ''
              }
              aria-label="Enter The Postal Code"
              placeholder="Enter The Postal Code"
              onChange={(value) => {
                dispatch({
                  type: 'SET_SHOW_SUBMIT_BTN',
                  data: false
                });
                debouncedZipHandler(value);
              }}
              suffix={
                widgetConfig?.zipCodeWidget?.geolocation && (
                  <Button
                    className="marker-btn"
                    onClick={getGeoLocation}
                    loading={geoLoading}
                    type="primary"
                    icon={
                      <img
                        src={MarkerIcon}
                        alt="import-icon"
                        width={11}
                        height={13}
                      />
                    }
                  />
                )
              }
              innerref={inputRef}
            />
          </Form.Item>
        </div>
      </LoaderComponent>
    </div>
  );
};

export default PostalWidgetComponent;
