import {
  DownOutlined,
  LeftOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Badge, Button, Statistic } from 'antd';
import { lowerCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import timerIcon from '../assets/timer.svg';
import { PRIMARY_COLOR, SAME_DAY_TIME } from '../common/constants';
import { formatPhoneNumber } from '../common/utils';
import LocationIconComponent from './LocationIconComponent';

const { Countdown } = Statistic;

const FooterSection = ({
  currentPageSequence = null,
  submitLoading,
  handleBack,
  handleRedirectToContact,
  handleClickCart,
  handleTrackExit,
  pageSequencesData = []
}) => {
  const {
    pageConfiguration: {
      sameDayInstallationTimer = false,
      showCtaButton = false,
      ctaButtonLabel = '',
      showMyCart = false,
      showBack = false,
      showFootNote = false,
      footNote = '',
      showContactMe = false,
      contactMe = '',
      showContactInfo = false,
      showLocation = false,
      widgetType = ''
    } = {},
    primaryColor = PRIMARY_COLOR,
    phoneNo = '',
    email = '',
    helpPage = '',
    liveChat = ''
  } = currentPageSequence || {};

  const {
    state: { showSubmitBtn, storedData, totalProject }
  } = useContext(AppContext);
  const [showTimer, setShowTimer] = useState(false);
  const [time, setTime] = useState(null);
  const [geoAddress, setGeoAddress] = useState(null);
  const [hideBackBtn, setHideBackBtn] = useState(true);

  const currentQuoteObj = { ...storedData };

  useEffect(() => {
    const todayDay = lowerCase(moment()?.format('dddd'));
    if (currentPageSequence?.cutOffTime?.[todayDay]?.checked) {
      if (
        moment(currentPageSequence?.cutOffTime?.[todayDay]?.time, [
          'h:mm A'
        ])?.isAfter(moment())
      ) {
        setShowTimer(true);
        setTime(currentPageSequence?.cutOffTime?.[todayDay]?.time);
      }
    }
    setGeoAddress(storedData?.shortAddress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //  we are hiding back button on cart page as if user delete all products it will remove all leadStore data.
    // so that we doesn't have any user choice stored. so only option is , redirect user to project page. by this
    // we are creating a new object . for more check handleDeleteProject() of MyCartComponent.js
    setHideBackBtn(widgetType === 'CART' && totalProject === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalProject]);

  const checkProtocol = (url) => {
    const pattern = /^https?:\/\//;
    return pattern.test(url);
  };

  return (
    <div className="footer-section">
      {sameDayInstallationTimer && showTimer && (
        <div className="countdown-text">
          <img src={timerIcon} alt="timer-icon" className="mr-4" />
          <Countdown
            value={moment(time, ['h:mm A'])?.unix() * 1000}
            onFinish={() => setShowTimer(false)}
          />
          <span className="ml-4">{SAME_DAY_TIME}</span>
        </div>
      )}
      {showSubmitBtn && showCtaButton && ctaButtonLabel?.length > 0 && (
        <div className="mt-16">
          <Button
            className="common-button"
            loading={submitLoading}
            htmlType="submit"
            id="submit-btn"
            type="primary"
          >
            {ctaButtonLabel}
          </Button>
        </div>
      )}
      {showMyCart && (
        <Button
          type="text"
          onClick={handleClickCart}
          icon={<DownOutlined />}
          className="my-cart-btn"
        >
          <Badge
            showZero
            count={totalProject}
            color={primaryColor}
            className="count-badge"
          />
          My Cart
        </Button>
      )}
      {pageSequencesData?.[0]?.id !== currentPageSequence?.id &&
        showBack &&
        !hideBackBtn &&
        storedData?.previousPageTitle && (
          <div className="mt-16 d-flex justify-center">
            <Button
              icon={<LeftOutlined />}
              className="back-btn"
              onClick={handleBack}
            >
              {storedData?.previousPageTitle}
            </Button>
          </div>
        )}
      {showFootNote && footNote?.length > 0 && (
        <div className="footnote-text">
          <span>{footNote}</span>
        </div>
      )}
      <div className="contact-section">
        {showContactMe && contactMe?.length > 0 && (
          <Button
            type="link"
            icon={<RightOutlined />}
            className="contact-me-btn"
            onClick={handleRedirectToContact}
          >
            {contactMe}
          </Button>
        )}
        {showContactInfo && (
          <div className="contact-info">
            {helpPage?.checked && (
              <span className="details-text">
                <a
                  href={
                    !checkProtocol(helpPage?.key)
                      ? `https://${helpPage?.key}`
                      : helpPage?.key
                  }
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleTrackExit}
                >
                  Do you need help?
                </a>
              </span>
            )}
            {liveChat?.checked && (
              <span className="details-text underline-text">
                <SendOutlined />
                <a
                  href={
                    !checkProtocol(helpPage?.key)
                      ? `https://${helpPage?.key}`
                      : helpPage?.key
                  }
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleTrackExit}
                >
                  Live Chat
                </a>
              </span>
            )}
            {phoneNo && (
              <span className="details-text underline-text">
                <PhoneOutlined rotate={90} />
                <a href={`tel:${phoneNo}`}>{formatPhoneNumber(phoneNo)}</a>
              </span>
            )}
            {email && (
              <span className="details-text underline-text">
                <MailOutlined />
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            )}
          </div>
        )}
      </div>
      {currentQuoteObj?.zipCode && showLocation && geoAddress && (
        <div className="location-footer">
          <LocationIconComponent color={primaryColor} />
          <span className="location-info">{geoAddress}</span>
        </div>
      )}
    </div>
  );
};

export default FooterSection;
