import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Popover } from 'antd';
import { kebabCase, map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { REGEX } from '../common/constants';
import {
  formValidatorRules,
  formatPhoneNumberWithoutMask,
  formatPrice,
  googleAnalyticsParams,
  sendAnalyticsData,
  updateDataMutation
} from '../common/utils';
import history from '../historyData';
import InputComponent from './InputComponent';
import LoaderComponent from './LoaderComponent';
import { getAddOnProducts } from './MyCartComponent';
import './styles/ContactWidgetComponent.less';

const { required, email } = formValidatorRules;

const ContactMeWidgetComponent = ({
  widgetConfig = null,
  pageSequencesData = [],
  finalProjectData = [],
  refetchLeadStoreData,
  form = null
}) => {
  const {
    dispatch,
    state: { storedData, projectLoading, totalProject }
  } = useContext(AppContext);
  const currentProject = { ...storedData };
  const location = useLocation();
  const queryParams = location?.state?.urlParams;

  const comments = Form?.useWatch('comments', form);
  const urgent = Form?.useWatch('urgent', form);
  const emailConfirmation = Form?.useWatch('emailConfirmation', form);
  const name = Form?.useWatch('name', form);
  const emailValue = Form?.useWatch('email', form);
  const mobileNumber = Form?.useWatch('mobileNumber', form);

  useEffect(() => {
    refetchLeadStoreData();
    const currentQuoteObj = { ...storedData };
    // check whether we have leadId or not
    if (currentQuoteObj?.leadId) {
      form?.setFieldsValue({
        comments: currentQuoteObj?.comments || null,
        mobileNumber: currentQuoteObj?.mobileNumber || null
      });
    }
    if (currentQuoteObj?.name) {
      form?.setFieldsValue({
        name: currentQuoteObj?.name
      });
    }

    if (currentQuoteObj?.email) {
      form?.setFieldsValue({
        email: currentQuoteObj?.email
      });
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      comments,
      urgent,
      emailConfirmation,
      name,
      email: emailValue,
      mobileNumber: formatPhoneNumberWithoutMask(mobileNumber)
    });
  }, [comments, urgent, emailConfirmation, name, emailValue, mobileNumber]);

  const handleChangeQuote = async (projectId) => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    const quotePage = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === 'QUOTES'
    );

    // finding a page key of previous page of quote page
    const indexOfPageBeforeQuotePage = pageSequencesData?.findIndex(
      (item) => item?.pageConfiguration?.widgetType === 'QUOTES'
    );
    const pageBeforeQuotePage =
      pageSequencesData?.[indexOfPageBeforeQuotePage - 1];

    const selectedProject = finalProjectData?.find(
      (project) => project?.id === projectId
    )?.data;

    const newProject = {
      ...selectedProject,
      currentQuote: true,
      previousPageKey: pageBeforeQuotePage?.pageKey,
      previousPageTitle: pageBeforeQuotePage?.title
    };

    if (projectId !== storedData?.id) {
      const oldProjectObj = {
        ...storedData,
        currentQuote: false,
        previousPageKey: pageBeforeQuotePage?.pageKey,
        previousPageTitle: pageBeforeQuotePage?.title
      };
      await updateDataMutation(oldProjectObj, dispatch, visitorId);
    }
    await updateDataMutation(newProject, dispatch, visitorId);
    history?.replace(
      `/${currentProject?.slug}/${kebabCase(
        quotePage?.pageKey
      )}${googleAnalyticsParams({ ...queryParams })}`,
      {
        urlParams: { ...queryParams }
      }
    );
  };

  const PackageDisplay = () => {
    let total = 0;

    return (
      <div>
        {map(finalProjectData, (project, index) => {
          let totalAddOnProduct = 0;
          const addOnProductsName = [];

          total += parseFloat(project?.data?.productPrice);
          const addOnProducts = getAddOnProducts(project);
          addOnProducts?.map((items) => {
            total += parseFloat(items?.totalPrice);
            addOnProductsName?.push(`${items?.quantity} x ${items?.name}`);
            totalAddOnProduct += parseFloat(items?.totalPrice);
            return null;
          });
          return (
            <div key={`${project?.data?.id}-${index}`}>
              <div className="fill-width d-flex justify-between align-center">
                <span className="bold-label package-title-quote-variant">
                  {project?.data?.productTitle}: {project?.data?.quoteVariant}
                </span>
                <div className="amount-section">
                  {formatPrice(project?.data?.productPrice)}
                </div>
              </div>
              {totalAddOnProduct > 0 && (
                <div className="fill-width d-flex justify-between align-center">
                  <div className="bold-label package-title-quote-variant">
                    <span className="add-on-text">Add On Products</span>
                    <Popover
                      trigger="hover"
                      content={map(addOnProductsName, (items, i) => {
                        return <p key={`${items}-${i}`}>{items}</p>;
                      })}
                      overlayClassName="common-tooltip"
                      placement="bottom"
                    >
                      <InfoCircleOutlined className="info-icon ml-4" />
                    </Popover>
                  </div>
                  <div className="amount-section">
                    {formatPrice(totalAddOnProduct)}
                  </div>
                </div>
              )}
              {/* collapse with description code goes here */}
              <Button
                type="link"
                icon={<LeftOutlined className="arrow" />}
                className="fill-width d-flex align-center change-my-quote-btn"
                onClick={() => handleChangeQuote(project?.id)}
              >
                Change Quote
              </Button>
            </div>
          );
        })}

        <Divider className="contact-divider" />
        <div className="fill-width d-flex justify-end align-end change-quote-section">
          <div className="amount-section">{formatPrice(total)}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="contact-page-wrapper">
      {widgetConfig?.orderSettings?.cartSummary && totalProject > 0 && (
        <LoaderComponent spinning={projectLoading} setHeight={10}>
          <PackageDisplay />
        </LoaderComponent>
      )}
      <div className="form-section fill-width">
        <span className="optional-comment">Optional comments</span>
        <div>
          <Form.Item name="comments">
            <InputComponent
              placeholder={
                widgetConfig?.contactFromSettings?.optionalComments?.labelText
              }
              className="mt-30"
            />
          </Form.Item>
          <div className="fill-width d-flex align-start flex-vertical">
            {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelCheck && (
              <Form.Item
                name="urgent"
                initialValue={
                  widgetConfig?.contactFromSettings
                    ?.urgencyCheckboxLabelSelected
                }
                valuePropName="checked"
              >
                <Checkbox className="mt-20 common-checkbox">
                  {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelText}
                </Checkbox>
              </Form.Item>
            )}

            {widgetConfig?.contactFromSettings
              ?.sendConfirmationEmailCheckboxCheck && (
              <Form.Item
                name="emailConfirmation"
                valuePropName="checked"
                initialValue={
                  widgetConfig?.contactFromSettings
                    ?.sendConfirmationEmailCheckboxSelected
                }
              >
                <Checkbox className="common-checkbox">
                  {
                    widgetConfig?.contactFromSettings
                      ?.sendConfirmationEmailCheckboxText
                  }
                </Checkbox>
              </Form.Item>
            )}
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                message: `Please Enter ${widgetConfig?.contactFromSettings?.name?.label}`
              }
            ]}
            name="name"
          >
            <InputComponent
              placeholder={`${widgetConfig?.contactFromSettings?.name?.label} *`}
              className="mt-30"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `Please Enter ${widgetConfig?.contactFromSettings?.email?.labelText}`
              },
              email
            ]}
          >
            <InputComponent
              placeholder={`${widgetConfig?.contactFromSettings?.email?.labelText} *`}
            />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            rules={[
              {
                ...required,
                message: `Please Enter ${widgetConfig?.contactFromSettings?.phone?.labelText}`
              },
              () => ({
                validator(rule, value) {
                  if (value) {
                    // eslint-disable-next-line no-param-reassign
                    value = value?.split(' ')?.join('');
                    const numberPattern = REGEX?.PHONE;
                    if (!numberPattern?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        `should be a valid ${widgetConfig?.contactFromSettings?.phone?.labelText}`
                      );
                    }
                  }
                  return Promise?.resolve();
                }
              })
            ]}
          >
            <ReactInputMask
              mask="(999) 999-9999"
              placeholder={`${widgetConfig?.contactFromSettings?.phone?.labelText} *`}
              allowClear
            >
              {(inputProps) => <InputComponent {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default ContactMeWidgetComponent;
