import { useLazyQuery } from '@apollo/client';
import { filter } from 'lodash';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { GET_LEAD_STORE_DATA } from '../graphql/Query';
import AddOnComponent from './AddOnComponent';
import AppointmentWidgetComponent from './AppointmentWidgetComponent';
import ContactMeWidgetComponent from './ContactWidgetComponent';
import ContentWidgetComponent from './ContentWidgetComponent';
import CustomerInfoComponent from './CustomerInfoComponent';
import DimensionWidgetComponent from './DimensionWidgetComponent';
import MyCartWidgetComponent from './MyCartComponent';
import MyProjectWidgetComponent from './MyProjectWidgetComponent';
import PostalWidgetComponent from './PostalWidgetComponent';
import QuoteWidgetComponent from './QuoteWidgetComponent';
import CustomWidgetComponent from './RenderCustomWidget';
import SystemWidgetComponent from './RenderSystemWidget';
import SupportWidgetComponent from './SupportWidgetComponent';

const WidgetSection = ({
  pageConfiguration = null,
  handleSubmitCta,
  setSavedData,
  pageSequencesData,
  currentPageSequence,
  form = null,
  savedData,
  fieldConfig = null,
  defaultPickListOption = null
}) => {
  const { dispatch } = useContext(AppContext);

  const [finalProjectData, setFinalProjectData] = useState([]);

  const [getLeadStoreData] = useLazyQuery(GET_LEAD_STORE_DATA, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setFinalProjectData(
        filter(
          res?.getLeadStoreData?.data,
          (project) => project?.data?.productTitle
        )
      );
      dispatch({
        type: 'SET_TOTAL_PROJECT_COUNT',
        data: filter(
          res?.getLeadStoreData?.data,
          (project) => project?.data?.productTitle
        )?.length
      });
      dispatch({
        type: 'SET_PROJECT_LOADING',
        data: false
      });
    },
    onError() {
      dispatch({
        type: 'SET_PROJECT_LOADING',
        data: false
      });
    }
  });

  const refetchLeadStoreData = async () => {
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: true
    });
    // eslint-disable-next-line no-undef
    const wsmTracker = window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    getLeadStoreData({
      variables: {
        where: {
          visitorId
        }
      }
    });
  };

  return (
    <div className="widget-section">
      {pageConfiguration?.widgetType === 'POSTAL' && (
        <PostalWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          handleSubmitCta={handleSubmitCta}
          setSavedData={setSavedData}
          form={form}
        />
      )}
      {pageConfiguration?.widgetType === 'SUPPORT' && (
        <SupportWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          handleSubmitCta={handleSubmitCta}
          pageSequencesData={pageSequencesData}
          currentPageSequence={currentPageSequence}
          form={form}
        />
      )}
      {pageConfiguration?.widgetType === 'PROJECT' && (
        <MyProjectWidgetComponent
          currentPageSequence={currentPageSequence}
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
        />
      )}
      {pageConfiguration?.widgetType === 'DIMENSION' && (
        <DimensionWidgetComponent
          currentPageSequence={currentPageSequence}
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
          form={form}
          savedData={savedData}
        />
      )}
      {pageConfiguration?.widgetType === 'QUOTES' && (
        <QuoteWidgetComponent
          pageConfigId={pageConfiguration?.id}
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
          savedData={savedData}
          pageSequencesData={pageSequencesData}
        />
      )}
      {pageConfiguration?.widgetType === 'APPOINTMENT' && (
        <AppointmentWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
          currentPageSequence={currentPageSequence}
          form={form}
          finalProjectData={finalProjectData}
          refetchLeadStoreData={refetchLeadStoreData}
        />
      )}
      {/* commented because of no use */}
      {/* {pageConfiguration?.widgetType === 'SERVICE' && (
        <ServiceTypeWidget
          widgetConfig={pageConfiguration?.widgetConfiguration}
          handleSubmitCta={handleSubmitCta}
          setSavedData={setSavedData}
        />
      )} */}
      {pageConfiguration?.widgetType === 'CUSTOM' && (
        <CustomWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          pageConfigId={pageConfiguration?.id}
          setSavedData={setSavedData}
          savedData={savedData}
          form={form}
          currentPageSequence={currentPageSequence}
        />
      )}
      {pageConfiguration?.widgetType === 'CONTACT' && (
        <ContactMeWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          handleSubmitCta={handleSubmitCta}
          pageSequencesData={pageSequencesData}
          currentPageSequence={currentPageSequence}
          finalProjectData={finalProjectData}
          refetchLeadStoreData={refetchLeadStoreData}
          form={form}
        />
      )}
      {pageConfiguration?.widgetType === 'CART' && (
        <MyCartWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          handleSubmitCta={handleSubmitCta}
          pageSequencesData={pageSequencesData}
          currentPageSequence={currentPageSequence}
          finalProjectData={finalProjectData}
          refetchLeadStoreData={refetchLeadStoreData}
        />
      )}
      {pageConfiguration?.widgetType === 'CONTENT' && (
        <ContentWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
        />
      )}
      {pageConfiguration?.widgetType === 'SYSTEM' && (
        <SystemWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
          savedData={savedData}
          form={form}
          fieldConfig={fieldConfig}
          defaultPickListOption={defaultPickListOption}
          currentPageSequence={currentPageSequence}
        />
      )}
      {pageConfiguration?.widgetType === 'ADDON' && (
        <AddOnComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
          form={form}
        />
      )}
      {pageConfiguration?.widgetType === 'CUSTOMER_INFO' && (
        <CustomerInfoComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          setSavedData={setSavedData}
          currentPageSequence={currentPageSequence}
          form={form}
          pageSequencesData={pageSequencesData}
          savedData={savedData}
        />
      )}
    </div>
  );
};

export default withRouter(WidgetSection);
