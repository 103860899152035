import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, message } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContextProvider } from './AppContext';
import Routes from './Routes';
import client from './apollo';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/main.less';

Sentry?.init({
  dsn: process?.env?.REACT_APP_SENTRY_DSN,
  environment: process?.env?.REACT_APP_ENV,
  tracesSampleRate: 1.0
});

const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <Elements stripe={stripePromise}>
        <Routes />
      </Elements>
    </AppContextProvider>
  </ApolloProvider>,
  // eslint-disable-next-line no-undef
  document?.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration?.register({
  onUpdate: (registration) => {
    if (registration?.waiting) {
      message?.info({
        content: (
          <>
            New version available! Click Reload to get the latest version.
            <Button
              className="ml-1 mb-0"
              type="link"
              onClick={() => {
                registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
                // eslint-disable-next-line no-undef
                window?.location?.reload();
              }}
            >
              Reload
            </Button>
          </>
        ),
        duration: 0
      });
    }
  }
});
