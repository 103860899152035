import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Carousel,
  Col,
  List,
  Popover,
  Row,
  Tabs,
  Typography
} from 'antd';
import { cloneDeep, findIndex, kebabCase, map, omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { AppContext } from '../AppContext';
import placeHolderImage from '../assets/images/place-holder-image-master.svg';
import {
  DYNAMIC_FIELDS_TYPE,
  SUB_DOMAIN,
  WATER_HEATER_KEY,
  WIDGET_TYPES
} from '../common/constants';
import {
  formatPrice,
  googleAnalyticsParams,
  sendAnalyticsData,
  updateDataMutation
} from '../common/utils';
import { FETCH_PRODUCTS, GET_ADD_ON_PRODUCTS } from '../graphql/Query';
import history from '../historyData';
import LoaderComponent from './LoaderComponent';
import QuoteCompareModal from './QuoteCompareModal';
import './styles/QuoteWidgetComponent.less';

export const qualityOptions = {
  lowCostOption: 'GOOD',
  recommendedOption: 'BETTER',
  premiumOption: 'BEST'
};

const { Paragraph } = Typography;

const displayPrice = (quoteLabel = null, formattedPrice = false) => {
  if (formattedPrice) {
    return formatPrice(quoteLabel?.sellingPrice);
  }
  return quoteLabel?.sellingPrice;
};

const QuoteWidgetComponent = ({
  widgetConfig = null,
  pageConfigId = null,
  setSavedData,
  savedData = null,
  pageSequencesData = []
}) => {
  const {
    state: { storedData, slug, showSubmitBtn },
    dispatch
  } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState('recommendedOption');
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const [showMyProjectBtn, setShowMyProjectBtn] = useState(false);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [isQuoteDataAvailable, setIsQuoteDataAvailable] = useState(true);
  const location = useLocation();
  const queryParams = location?.state?.urlParams;

  const [fetchProducts] = useLazyQuery(FETCH_PRODUCTS, {
    onCompleted: (res) => {
      setProductData(res?.fetchProducts);
      setLoading(false);
    },
    // Keep it no cache as network only is caching the Product Item and causing error in final mutation
    fetchPolicy: 'no-cache',
    onError: () => {
      setLoading(false);
    }
  });

  const { zipCode, navigateTo } = storedData;
  const { productId } = savedData ?? {};

  const [addOnProducts] = useLazyQuery(GET_ADD_ON_PRODUCTS, {
    onCompleted: (res) => {
      setSavedData((prev) => ({
        ...prev,
        skipAddOnProduct: !res?.addOnProducts?.data?.length > 0
      }));
    },
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  useEffect(() => {
    const {
      allowDuplicateProductsFromPackage: allowProductsFromPackage = false,
      minProductsToOffer: min = 0,
      maxProductsToOffer: max = 10
    } = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.ADD_ON
    )?.pageConfiguration?.widgetConfiguration;

    if (
      productId &&
      widgetConfig?.[selectedTab]?.title &&
      navigateTo &&
      zipCode
    ) {
      addOnProducts({
        variables: {
          where: {
            packageId: productId,
            quality: qualityOptions?.[selectedTab],
            questionnaireId: navigateTo,
            zipCode,
            allowProductsFromPackage,
            min: parseFloat(min),
            max: parseFloat(max)
          }
        }
      });
    } else {
      setSavedData((prev) => ({
        ...prev,
        skipAddOnProduct: true
      }));
    }
  }, [
    addOnProducts,
    productId,
    widgetConfig,
    selectedTab,
    navigateTo,
    zipCode,
    pageSequencesData,
    setSavedData
  ]);

  const changeTab = (key = '', productsConfig = null) => {
    setSelectedTab(key);
    let productDetails;
    const lowCostCopy = {
      ...widgetConfig?.lowCostOption,
      ...productsConfig?.good
    };
    const recommendedCopy = {
      ...widgetConfig?.recommendedOption,
      ...productsConfig?.better
    };
    const premiumCopy = {
      ...widgetConfig?.premiumOption,
      ...productsConfig?.best
    };

    switch (key) {
      case 'lowCostOption':
        productDetails = {
          productId: lowCostCopy?.id,
          productTitle: lowCostCopy?.title,
          productImageUrl: lowCostCopy?.attachments?.[0]?.url,
          productPrice: lowCostCopy?.sellingPrice,
          primaryProduct: lowCostCopy?.productItemInfo?.find(
            (product) => product?.isPrimary
          ),
          productDescription: lowCostCopy?.description
        };
        if (!storedData?.leadId && !storedData?.visitorId) {
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: !!productsConfig?.good
          });
        }
        // Added as now partial lead will also generate Lead Id
        else if (storedData?.leadId) {
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: !!productsConfig?.good
          });
        }

        setIsQuoteDataAvailable(!!productsConfig?.good);
        break;
      case 'recommendedOption':
        productDetails = {
          productId: recommendedCopy?.id,
          productTitle: recommendedCopy?.title,
          productImageUrl: recommendedCopy?.attachments?.[0]?.url,
          productPrice: recommendedCopy?.sellingPrice,
          primaryProduct: recommendedCopy?.productItemInfo?.find(
            (product) => product?.isPrimary
          ),
          productDescription: recommendedCopy?.description
        };

        if (!storedData?.leadId && !storedData?.visitorId) {
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: !!productsConfig?.better
          });
        }
        // Added as now partial lead will also generate Lead Id
        else if (storedData?.leadId) {
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: !!productsConfig?.better
          });
        }
        setIsQuoteDataAvailable(!!productsConfig?.better);
        break;
      case 'premiumOption':
        productDetails = {
          productId: premiumCopy?.id,
          productTitle: premiumCopy?.title,
          productImageUrl: premiumCopy?.attachments?.[0]?.url,
          productPrice: premiumCopy?.sellingPrice,
          primaryProduct: premiumCopy?.productItemInfo?.find(
            (product) => product?.isPrimary
          ),
          productDescription: premiumCopy?.description
        };
        if (!storedData?.leadId && !storedData?.visitorId) {
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: !!productsConfig?.best
          });
        }
        // Added as now partial lead will also generate Lead Id
        else if (storedData?.leadId) {
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: !!productsConfig?.best
          });
        }
        setIsQuoteDataAvailable(!!productsConfig?.best);
        break;
      default:
        break;
    }

    if (
      !productsConfig?.good &&
      !productsConfig?.better &&
      !productsConfig?.best
    ) {
      // currently hide add new Project button when user does not have any quote suggestions and project page is already skipped through params
      setShowMyProjectBtn(
        !(storedData?.urlParams?.lob && storedData?.urlParams?.sa)
      );
    }

    const newData = {
      productId: productDetails?.productId,
      productTitle: productDetails?.productTitle,
      productImageUrl: productDetails?.productImageUrl,
      productPrice: productDetails?.productPrice,
      productItemId: productDetails?.primaryProduct?.id,
      productDescription: productDetails?.productDescription,
      products: productsConfig,
      selectedTab: key,
      quoteVariant: widgetConfig?.[key]?.title
    };
    setSavedData(newData);
  };

  useEffect(() => {
    const currentQuoteData = { ...storedData };

    let conversionInputFilter = null;

    if (currentQuoteData?.dimension) {
      conversionInputFilter = currentQuoteData?.dimensionFilter;
    }

    const finalSystemFields = map(
      currentQuoteData?.systemFields,
      (item, key) => {
        if (item?.[DYNAMIC_FIELDS_TYPE?.NUMBER]) {
          const numberInput = item?.[
            DYNAMIC_FIELDS_TYPE?.NUMBER
          ]?.value?.toString();
          return {
            identifier: key,
            type: DYNAMIC_FIELDS_TYPE?.NUMBER,
            value: [numberInput]
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]) {
          return {
            identifier: key,
            type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
            value: item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.TEXT]) {
          return {
            identifier: key,
            type: DYNAMIC_FIELDS_TYPE?.TEXT,
            value: item?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.value
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.BOOLEAN]) {
          return {
            identifier: key,
            type: DYNAMIC_FIELDS_TYPE?.BOOLEAN,
            value: item?.[DYNAMIC_FIELDS_TYPE?.BOOLEAN]?.value
          };
        }
        if (item?.type) {
          return omit(
            {
              identifier: key,
              ...item
            },
            'pageId'
          );
        }
      }
    );

    fetchProducts({
      variables: {
        where: {
          lineOfBusiness: currentQuoteData?.lobObject?.blockTitle,
          subAreaId: currentQuoteData?.serviceTypeObject?.blockId,
          zipCode: currentQuoteData?.zipCode,
          navigateTo: currentQuoteData?.navigateTo,
          slug,
          subDomain:
            process?.env?.REACT_APP_ENV === 'localhost'
              ? SUB_DOMAIN
              : // eslint-disable-next-line no-undef
                window?.location?.hostname,
          pageConfigId,
          systemFields: finalSystemFields,
          conversionInputFilter
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productData) {
      const data = { ...storedData };

      if (data?.selectedTab) {
        changeTab(data?.selectedTab, productData);
      } else if (widgetConfig?.lowCostOption?.default) {
        changeTab('lowCostOption', productData);
      } else if (widgetConfig?.recommendedOption?.default) {
        changeTab('recommendedOption', productData);
      } else {
        changeTab('premiumOption', productData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      selectedTab: savedData?.selectedTab,
      quoteVariant: savedData?.quoteVariant,
      packageTitle: savedData?.productTitle,
      packagePrice: savedData?.productPrice
    });
  }, [savedData]);

  const handlers = useSwipeable({
    // eslint-disable-next-line no-console
    onSwipedLeft: () => {
      if (selectedTab === 'lowCostOption') {
        changeTab('recommendedOption', productData);
      } else if (selectedTab === 'recommendedOption') {
        changeTab('premiumOption', productData);
      }
    },
    // eslint-disable-next-line no-console
    onSwipedRight: () => {
      if (selectedTab === 'premiumOption') {
        changeTab('recommendedOption', productData);
      } else if (selectedTab === 'recommendedOption') {
        changeTab('lowCostOption', productData);
      }
    },
    delta: 10, // min distance(px) before a swipe starts.
    preventDefaultTouchmoveEvent: false, // call e.preventDefault
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0 // set a rotation angle
  });

  const renderProducts = (product = null) => {
    let productNameSpan;

    // below condition are render our product name row  based on config
    if (widgetConfig?.packageSettings?.itemizedPricing) {
      if (!widgetConfig?.packageSettings?.productQuantityCheck) {
        productNameSpan = 20; // quantity = hide , name = show , price = show
      } else if (storedData?.lobObject?.blockKey !== WATER_HEATER_KEY) {
        // for dimension we have to change value of name span as we are showing volume of product (cu/ft) instead of qty
        productNameSpan = 14; // quantity = show , name = show , price = hide
      } else {
        productNameSpan = 18; // quantity = show , name = show , price = hide
      }
    } else if (product?.volume) {
      productNameSpan = 16;
    } else {
      productNameSpan = 10;
    }

    // currently we are having only two INDUSTRY and limited LOB'S. so as of now added condition based on block key to identify whether we want to show UOM or QUANTITY
    // but in future we can manage this with some key in service type
    return (
      <List.Item key={product?.id}>
        {storedData?.lobObject?.blockKey !== WATER_HEATER_KEY ? (
          <Row className="fill-width" align="middle">
            {widgetConfig?.packageSettings?.productQuantityCheck && (
              <Col span={6}>
                {product?.convertedVolume && product?.symbol ? (
                  <span>{`${parseFloat(product?.convertedVolume)?.toFixed(2)} ${
                    product?.symbol || '-'
                  }`}</span>
                ) : (
                  <span>-</span>
                )}
              </Col>
            )}
            <Col span={productNameSpan}>
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: 'Read More'
                }}
                className="product-name"
                title={product?.quoteDescription || product?.name}
              >
                {product?.quoteDescription || product?.name}
              </Paragraph>
            </Col>

            {widgetConfig?.packageSettings?.itemizedPricing && (
              <Col span={4} className="price-col">
                <span className="product-price">
                  {formatPrice(product?.price)}
                </span>
              </Col>
            )}
          </Row>
        ) : (
          <Row className="fill-width" align="middle">
            {widgetConfig?.packageSettings?.productQuantityCheck && (
              <Col span={2}>
                <span className="quantity">{`${product?.quantity}x`}</span>
              </Col>
            )}
            <Col span={productNameSpan}>
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: 'Read More'
                }}
                className="product-name"
                title={product?.quoteDescription || product?.name}
              >
                {product?.quoteDescription || product?.name}
              </Paragraph>
            </Col>

            {widgetConfig?.packageSettings?.itemizedPricing && (
              <Col span={4} className="price-col">
                <span className="product-price">
                  {formatPrice(product?.price)}
                </span>
              </Col>
            )}
          </Row>
        )}
      </List.Item>
    );
  };

  const tabContents = (quoteLabel = null) => {
    const price = displayPrice(quoteLabel, false);
    return (
      <>
        {widgetConfig?.compareCheck && (
          <Button size="large" className="compare-quote-btn">
            {widgetConfig?.compareSection?.inputButton}
          </Button>
        )}
        <Carousel
          className="common-carousel"
          dots={false}
          arrows
          prevArrow={<LeftOutlined />}
          nextArrow={<RightOutlined />}
        >
          {widgetConfig?.packageSettings?.packageImage &&
            (quoteLabel?.attachments?.length > 0 ? (
              map(quoteLabel?.attachments, (item) => (
                <div key={item?.id} className="product-image">
                  <img alt={item?.label} src={item?.url} />
                </div>
              ))
            ) : (
              <div className="product-image">
                <img alt="no-product" src={placeHolderImage} />
              </div>
            ))}
        </Carousel>
        <div className="product-label">
          {widgetConfig?.packageSettings?.packageTitle &&
            (!isQuoteDataAvailable ? (
              <span className="no-product-text">Product Not Found</span>
            ) : (
              quoteLabel?.title
            ))}
        </div>
        {quoteLabel?.description ? (
          <div
            className="package-description editor-render"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: quoteLabel?.description }}
          />
        ) : (
          !showSubmitBtn && (
            <div className="package-description">
              We're sorry, but the product/package you are looking for could not
              be found in our database. Please review your search term and try
              again.
            </div>
          )
        )}
        {productData?.compareConfigData?.enable && (
          <div>
            <Button
              onClick={() => {
                setShowComparisonModal(true);
              }}
              className="mt-16"
            >
              {productData?.compareConfigData?.label ||
                'Compare Quote Variants'}
            </Button>
          </div>
        )}
        {quoteLabel?.productItemInfo?.length > 0 && (
          <>
            {widgetConfig?.packageSettings?.showProducts && (
              <List
                dataSource={quoteLabel?.productItemInfo}
                renderItem={(item) => renderProducts(item)}
              />
            )}
          </>
        )}

        {price > 0 && (
          <div className="price-card">
            <div className="price-section">
              <div className="price-row">
                {quoteLabel?.price?.icon?.url && (
                  <img
                    alt="price-icon"
                    className="price-icon"
                    src={quoteLabel?.price?.icon?.url}
                  />
                )}
                <span className="price">{formatPrice(price)}</span>
              </div>
              {quoteLabel?.price?.costPerDay && quoteLabel?.costPerDay > 0 && (
                <div>
                  <span className="cost-per-day">
                    Cost per day: {formatPrice(quoteLabel?.costPerDay)}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="price-description">
          {quoteLabel?.price?.descriptionCheck &&
            quoteLabel?.price?.descriptionText?.length > 0 && (
              <span>{quoteLabel?.price?.descriptionText}</span>
            )}{' '}
          <span className="price-factor">
            <Popover
              overlayClassName="overlay-text"
              placement="topLeft"
              content={
                <span>
                  {quoteLabel?.price?.tooltipDescriptionCheck &&
                    quoteLabel?.price?.tooltipDescriptionText?.length > 0 &&
                    quoteLabel?.price?.tooltipDescriptionText}
                </span>
              }
            >
              {quoteLabel?.price?.toolTipCheck &&
                quoteLabel?.price?.toolTipText?.length > 0 && (
                  <span>{quoteLabel?.price?.toolTipText}</span>
                )}
            </Popover>
          </span>
        </div>
        <div className="marketing-section">
          <div className="marketing-card customer-card">
            {quoteLabel?.marketingBlock?.icon?.url && (
              <img
                alt="marketing-block-icon"
                className="marketing-icon"
                src={quoteLabel?.marketingBlock?.icon?.url}
              />
            )}
            {quoteLabel?.marketingBlock?.title?.length > 0 && (
              <span className="marketing-title">
                {quoteLabel?.marketingBlock?.title}
              </span>
            )}
            {quoteLabel?.marketingBlock?.description?.length > 0 && (
              <span className="marketing-description">
                {quoteLabel?.marketingBlock?.description}
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  const tabLabel = (configOption = 'lowCostOption') => {
    return (
      <div
        className={`d-flex flex-vertical align-center ${configOption}-focus-visible`}
      >
        {widgetConfig?.icons && (
          <img
            alt="quote-icon"
            className="quote-icon"
            src={
              widgetConfig?.[configOption]?.quoteVariantIcon?.url ||
              placeHolderImage
            }
          />
        )}
        <span>{widgetConfig?.[configOption]?.title}</span>
      </div>
    );
  };

  const tabItems = [
    widgetConfig?.lowCostOption?.enable && {
      label: tabLabel('lowCostOption'),
      key: 'lowCostOption',
      children: tabContents({
        ...widgetConfig?.lowCostOption,
        ...productData?.good
      })
    },
    widgetConfig?.recommendedOption?.enable && {
      label: tabLabel('recommendedOption'),
      key: 'recommendedOption',
      children: tabContents({
        ...widgetConfig?.recommendedOption,
        ...productData?.better
      })
    },
    widgetConfig?.premiumOption?.enable && {
      label: tabLabel('premiumOption'),
      key: 'premiumOption',
      children: tabContents({
        ...widgetConfig?.premiumOption,
        ...productData?.best
      })
    }
  ];

  // storedData?.leadId &&   verify whether it is a change quote flow
  // storedData?.visitorId && verify whether it is a change quote flow
  useEffect(() => {
    if (
      storedData?.selectedTab &&
      storedData?.leadId &&
      storedData?.visitorId
    ) {
      const tabClone = cloneDeep(tabItems);
      const alreadySelectedTabIndex = tabClone?.findIndex(
        (tabItem) => tabItem.key === storedData?.selectedTab
      );
      const filteredTabs = tabClone?.filter(
        (tabItem, index) => index > alreadySelectedTabIndex
      );

      const showBtn = filteredTabs?.some((item) => item.key === selectedTab);
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: showBtn
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedData, selectedTab]);

  const handleChangeMyProject = async () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    // for back to project button log visit track, need to set ping true for back to project button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();

    const currentQuoteObj = { ...storedData };

    const projectPageIndex = findIndex(
      pageSequencesData,
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.PROJECT
    );

    const projectPageConfig = pageSequencesData?.[projectPageIndex];
    const pageBeforeProject = pageSequencesData?.[projectPageIndex - 1];
    let previousPage;
    if (
      pageBeforeProject?.pageConfiguration?.widgetType === WIDGET_TYPES?.SUPPORT
    ) {
      previousPage = pageSequencesData?.[projectPageIndex - 2];
    } else {
      previousPage = pageSequencesData?.[projectPageIndex - 1];
    }
    const data = {
      ...currentQuoteObj,
      previousPageKey: previousPage?.pageKey,
      previousPageTitle: previousPage?.title
    };

    await updateDataMutation(data, dispatch, visitorId);

    history?.replace(
      `/${slug}/${kebabCase(
        projectPageConfig?.pageKey
      )}${googleAnalyticsParams({ ...queryParams })}`,
      {
        urlParams: { ...queryParams }
      }
    );
  };

  if (loading) {
    return <LoaderComponent setHeight={10} />;
  }

  return (
    <div className="quotes-page">
      {widgetConfig?.brandInfo?.logo?.url && (
        <div className="logo-section">
          <img alt="logo" src={widgetConfig?.brandInfo?.logo?.url} />
        </div>
      )}
      <div className="tab-section" {...handlers}>
        <Tabs
          className={`product-tabs ${
            selectedTab === 'recommendedOption' ? 'recommended-transform' : ''
          } ${widgetConfig?.icons ? 'label-with-icon' : ''}`}
          animated
          activeKey={selectedTab}
          onChange={(key) => changeTab(key, productData)}
          size="large"
          centered
          items={tabItems}
        />
      </div>
      {showMyProjectBtn && (
        <div className="mt-16">
          <Button
            className="common-button"
            onClick={handleChangeMyProject}
            id="back-btn"
            type="primary"
          >
            Go Back to My Projects
          </Button>
        </div>
      )}

      {showComparisonModal && (
        <QuoteCompareModal
          showComparisonModal={showComparisonModal}
          setShowComparisonModal={setShowComparisonModal}
          productData={productData}
        />
      )}
    </div>
  );
};

export default QuoteWidgetComponent;
